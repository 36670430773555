import Layout from "../components/layouts/layout";
import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "../assets/Images/Homepage/search_digi.svg";
import CloseIcon from "../assets/Images/Homepage/close_x.svg";
import SendIconWhite from "../assets/Images/Homepage/send_white.svg";
import SendIcon from "../assets/Images/Homepage/send_black.svg";
import Button from "../components/Button";
//Image Banner
import { BASE_SITE_URL } from "../shared/constants";

const Search = () => {
  const [searchValue, handleSearchValue] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleOpen();
    // SearchClient.getSearchResults(searchValue).then((res)=>{
    //     if(res.success){
    //         setSearchResult(res.result);
    //     }
    //     setLoading(false);
    // }).catch((e)=>{
    //     setLoading(false);
    // })
  };
  const [isSearch, setIsSearch] = useState(false);
  const toggleSearch = () => {
    setIsSearch(!isSearch);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const searchInputRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpen = (event) => {
    setAnchorEl(searchInputRef.current);
  };

  const handleClose = () => {
    handleSearchValue("");
  };
  const open = Boolean(anchorEl);
  const id = open ? "search-results-popover" : undefined;
  // console.log('searchValue :>> ', searchValue);
  return (
    <Layout
      seo={{
        title: "Search",
        ogtitle: "Search",
        description: "Welcome to Search Page",
        ogurl: `${BASE_SITE_URL}/search`,
      }}
    >
      <div className="mt-70">.</div>
      <div className="mt-70 body-container">
        <div className="title-header">
          <span className="red-line3letter">Sea</span>rch
        </div>
      </div>
      <div className="mt-70 body-container">
        <form onSubmit={handleSubmit}>
          <Paper className="search-style-no-margin" elevation={0}>
            <div className="input-search-no-margin">
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                style={{ pointerEvents: "none" }}
              >
                <img src={SearchIcon} />
              </IconButton>
              {/* <SearchIcon style={{paddingTop:'10px'}} fontSize="default" /> */}
              <InputBase
                // className="input-search-no-margin"
                placeholder="Search..."
                // ref={searchInputRef}
                value={searchValue}
                onChange={(e) => handleSearchValue(e.target.value)}
                inputProps={{ "aria-label": "Search", "aria-describedby": id }}
              />
              <span style={{ marginLeft: "71.8%" }}>
                {searchValue !== "" ? (
                  <React.Fragment>
                    {" "}
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      edge="end"
                      onClick={handleClose}
                    >
                      <img src={CloseIcon} />
                    </IconButton>
                    <React.Fragment>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </React.Fragment>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </React.Fragment>
                )}

                <IconButton
                  size="small"
                  aria-label="close"
                  className="send-bg"
                  edge="end"
                >
                  <img src={SendIconWhite} />
                </IconButton>
              </span>
            </div>
          </Paper>
        </form>
      </div>
      <div className="mt-70 body-container">
        <h2 className="plain-card-title">Material Search Bar</h2>
        <h3 style={{ fontWeight: "normal" }} className="approval-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </h3>
      </div>
    </Layout>
  );
};

export default Search;
